import { DeleteOutlined } from '@ant-design/icons';
import { AgendaLabel, IAgendaItem } from '@shared/meetings';
import { del, patch } from '@web/common/api';
import { Button, PrimaryButton } from '@web/components/Button';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Header1 } from '@web/components/typography';
import { Input, Select, Tag, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';

export const EditAgendaItemDetails: React.FC<{
  agendaItem?: IAgendaItem;
  onChange: (agendaItem: IAgendaItem) => void;
  onCancel: () => void;
}> = ({ agendaItem, onChange, onCancel }) => {
  const [title, setTitle] = React.useState(agendaItem.title);
  const [description, setDescription] = React.useState(agendaItem.description);
  const [label, setLabel] = React.useState(agendaItem.labels[0]);

  const handleSave = async () => {
    await patch(
      `/meetings/${agendaItem.meetingToken}/agenda_items/${agendaItem.token}`,
      {
        title,
        description,
        labels: label ? [label] : [],
      },
    );
    void message.success('Agenda item saved');
    onChange(agendaItem);
  };

  const handleDelete = async () => {
    try {
      await del(
        `/meetings/${agendaItem.meetingToken}/agenda_items/${agendaItem.token}`,
      );
      void message.success('Success');
      void onChange(agendaItem);
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column
      style={{ padding: 24, boxShadow: '0 0 10px rgba(0,0,0,0.3)', zIndex: 2 }}
      gap={24}
    >
      <Row>
        <Header1>Edit Agenda Item</Header1>
        <GrowingSpacer />
        <Button
          onClick={() => {
            if (
              confirm(
                'Are you sure you want to remove this agenda item from this meeting?',
              )
            ) {
              void handleDelete();
            }
          }}
        >
          <DeleteOutlined />
        </Button>
      </Row>
      <Column gap={3}>
        {agendaItem.resolvedDate && (
          <Tag style={{ alignSelf: 'flex-start', marginLeft: 11 }}>
            Resolved
          </Tag>
        )}
        <Row gap={12}>
          <Input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />

          <Select
            placeholder="Select label"
            style={{ maxWidth: 150 }}
            onChange={(newLabel) => {
              setLabel(newLabel);
            }}
            value={label}
            allowClear
          >
            <Select.Option value={AgendaLabel.APPROVAL}>
              {AgendaLabel.APPROVAL}
            </Select.Option>
            <Select.Option value={AgendaLabel.DISCUSS}>
              {AgendaLabel.DISCUSS}
            </Select.Option>
            <Select.Option value={AgendaLabel.INFO}>
              {AgendaLabel.INFO}
            </Select.Option>
          </Select>
        </Row>
        <Spacer size={12} />
        <TextArea
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
          style={{ height: 200 }}
        />

        <Spacer size={24} />
        <Row gap={6}>
          <PrimaryButton
            onClick={() => {
              void handleSave();
            }}
          >
            Save
          </PrimaryButton>
          <Button
            onClick={() => {
              onCancel();
            }}
          >
            Cancel
          </Button>
        </Row>
      </Column>
    </Column>
  );
};
