import { InternalEditEmbeddingPage } from '@web/internal/InternalEditEmbeddingPage';
import { InternalEditFilePage } from '@web/internal/InternalEditFilePage';
import { InternalFileEmbeddingsPage } from '@web/internal/InternalFileEmbeddingsPage';
import { InternalFilesPage } from '@web/internal/InternalFilesPage';
import * as React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { EditUserPage, OrganizationPage, UsersPage } from '../lazy';

export const internalRoutes = () => (
  <Route path="organizations">
    <Route path=":organizationToken">
      <Route index element={<Navigate to="details" replace />} />
      <Route path="details" element={<OrganizationPage />} />
      <Route path="users">
        <Route index element={<UsersPage />} />
        <Route path=":userToken" element={<EditUserPage />} />
      </Route>
      <Route path="files">
        <Route index element={<InternalFilesPage />} />
        <Route path=":fileToken/details" element={<InternalEditFilePage />} />
        <Route path=":fileToken/embeddings">
          <Route index element={<InternalFileEmbeddingsPage />} />
          <Route path="new" element={<InternalEditEmbeddingPage />} />
          <Route
            path=":embeddingToken"
            element={<InternalEditEmbeddingPage />}
          />
        </Route>
      </Route>
    </Route>
  </Route>
);
