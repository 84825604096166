import { IFolder, ROOT_FOLDER_TOKEN, isRootFolder } from '@shared/files';
import { IF_MOBILE } from '@web/app/responsive';
import { Droppable } from '@web/components/draggables';
import { Row, Spacer } from '@web/components/layout';
import { TypographyCss } from '@web/components/typography';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const FolderHeader: React.FC<{
  folder: IFolder;
}> = ({ folder }) => {
  if (isRootFolder(folder)) {
    return <HeaderLink to="/explore">Homea</HeaderLink>;
  }

  return (
    <Row>
      <Droppable
        data={{ token: ROOT_FOLDER_TOKEN }}
        id={`parent-folder-${ROOT_FOLDER_TOKEN}`}
      >
        <HeaderLink to="/explore">Home</HeaderLink>
      </Droppable>
      {folder.pathFolders.map((pathFolder) => {
        return (
          <React.Fragment key={`folder-${pathFolder.token}`}>
            <Spacer size={3} />
            <HeaderText style={{ color: '#aaa' }}>/</HeaderText>
            <Spacer size={3} />
            <Droppable
              data={pathFolder}
              id={`parent-folder-${pathFolder.token}`}
            >
              <HeaderLink to={`/explore/${pathFolder.token}`}>
                {pathFolder.name}
              </HeaderLink>
            </Droppable>
          </React.Fragment>
        );
      })}
      <Spacer size={3} />
      <HeaderText style={{ color: '#aaa' }}>/</HeaderText>
      <Spacer size={3} />
      <HeaderText>{folder.name}</HeaderText>
    </Row>
  );
};

const HeaderLink = styled(Link)`
  ${TypographyCss.Text}
  margin: 0;
  color: #888;

  ${IF_MOBILE} {
    font-size: 14px;
  }

  &:hover {
    color: var(--primary-color);
  }
`;

const HeaderText = styled.p`
  ${TypographyCss.Text}
  margin: 0;
  color: #333;

  ${IF_MOBILE} {
    font-size: 14px;
  }
`;
