import { PageContent } from '@web/app/Page';
import { Result, ResultProps } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

import { Button } from './Button';

export const ErrorPageContent: React.FC<{
  statusCode?: number;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  extra?: React.ReactNode;
}> = ({ statusCode, title, subTitle, extra }) => {
  let status: ResultProps['status'] = 'error';
  if (statusCode === 403 || statusCode === 404 || statusCode === 500) {
    status = statusCode;
  }
  const statusCodeSubTitle =
    statusCode === 403
      ? 'Sorry, you are not authorized to access this page.'
      : 'Sorry, something went wrong loading this page.';
  return (
    <PageContent>
      <Result
        status={status}
        title={title ?? 'An error occurred'}
        subTitle={subTitle ?? statusCodeSubTitle}
        extra={
          extra ?? (
            <Link to="/topics">
              <Button>Return to Board Topics</Button>
            </Link>
          )
        }
      />
    </PageContent>
  );
};
