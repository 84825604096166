import { FolderOutlined } from '@ant-design/icons';
import { createFolder, updateFolder } from '@client/FilesClient';
import { FolderToken, IFolder } from '@shared/files';
import { Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Input, InputRef, Modal, message } from 'antd';
import * as React from 'react';

export const EditFolderModal: React.FC<{
  folder?: IFolder;
  parentFolderToken?: FolderToken;
  onSave: (saved: IFolder) => void;
  onClose: () => void;
}> = ({ folder, onClose, onSave, parentFolderToken }) => {
  const nameInputRef = React.useRef<InputRef>();
  const [name, setName] = React.useState(folder?.name);
  const [description, setDescription] = React.useState(folder?.description);
  React.useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, [nameInputRef.current]);

  const handleOkClicked = async () => {
    if (!name) {
      return;
    }

    try {
      if (folder) {
        const saved = await updateFolder(folder.token, name, description);
        onSave(saved);
      } else {
        const saved = await createFolder(name, description, parentFolderToken);
        onSave(saved);
      }
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Modal
      title={folder ? 'Edit Folder' : 'Create Folder'}
      open={true}
      onOk={handleOkClicked}
      onCancel={onClose}
      okButtonProps={{ disabled: !name }}
      width={500}
      okText="Save"
    >
      <Spacer size={12} />
      <Text>Name</Text>
      <Input
        ref={nameInputRef}
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            void handleOkClicked();
          }
        }}
        prefix={<FolderOutlined />}
      />
      <Spacer size={12} />
      <Text>Description</Text>
      <Input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <Spacer />
    </Modal>
  );
};
