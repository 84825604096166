import { EllipsisOutlined } from '@ant-design/icons';
import { Button } from '@web/components/Button';
import { Row } from '@web/components/layout';
import { Dropdown, MenuProps, Typography } from 'antd';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { IPageAction } from './PageContext';
import { useResponsive } from './responsive';

export const PageActions: React.FC<{
  primaryAction?: IPageAction;
  secondaryActions?: IPageAction[];
}> = ({ primaryAction, secondaryActions }) => {
  const { isMobile } = useResponsive();
  const navigate = useNavigate();

  if (!primaryAction && !secondaryActions) {
    return null;
  }

  const secondaryMenuItems: MenuProps['items'] = (secondaryActions ?? [])
    ?.filter((action) => !!action)
    .map((action, index) => ({
      key: index,
      icon: action.icon,
      label: (
        <Typography.Text style={{ lineHeight: '40px' }}>
          {action.label}
        </Typography.Text>
      ),
      disabled: action.disabled,
      onClick: () => {
        handleActionClicked(action);
      },
    }));

  const handleActionClicked = ({ linkTo, onClick }: IPageAction) => {
    if (linkTo) {
      navigate(linkTo);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <ActionsContainer>
      {primaryAction && <PrimaryActionButton {...primaryAction} />}
      {secondaryMenuItems.length > 0 && (
        <Dropdown
          placement={isMobile ? 'topRight' : 'bottomRight'}
          menu={{ items: secondaryMenuItems }}
          trigger={['click']}
        >
          <Button>
            <EllipsisOutlined />
          </Button>
        </Dropdown>
      )}
    </ActionsContainer>
  );
};

const ActionsContainer = styled(Row)`
  gap: 6px;

  .ant-btn {
    min-width: 40px;
  }
`;

const PrimaryActionButton: React.FC<IPageAction> = ({
  icon,
  label,
  linkTo,
  onClick,
  disabled,
}) => {
  const navigate = useNavigate();

  const handleConfirm = () => {
    if (linkTo) {
      navigate(linkTo);
    } else if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      style={{ minWidth: 40 }}
      onClick={handleConfirm}
      disabled={disabled}
    >
      {icon}
      {label}
    </Button>
  );
};
