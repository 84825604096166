import { useAuth } from '@web/auth/useAuth';
import { useNavigateBack } from '@web/common/useNavigateBack';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { ImpersonationBanner } from './ImpersonationBanner';
import { MobilePageHeader } from './MobilePageHeader';
import { IF_MOBILE, useResponsive } from './responsive';
import { CustomerSidebar, InternalSidebar, SIDEBAR_WIDTH } from './sidebars';

export interface IPageProps {
  children: React.ReactNode;
}

export const PageContent: React.FC<{
  children: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => (
  <PageContentContainer style={style}>{children}</PageContentContainer>
);

export const PageContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  padding: 24px;

  ${IF_MOBILE} {
    padding-top: 0;
  }
`;

export const Page: React.FC<IPageProps> = ({ children }) => {
  const { isMobile } = useResponsive();
  const { isSuper, isLoggedIn } = useAuth();
  const showingSidebar = isLoggedIn && !isMobile;
  const sideBar = isSuper ? <InternalSidebar /> : <CustomerSidebar />;

  return (
    <PageContainer>
      {isMobile && <MobilePageHeader menu={sideBar} />}
      <Layout>
        <RightSide showingSidebar={showingSidebar}>{children}</RightSide>
        <ImpersonationBanner />
      </Layout>
      {showingSidebar && <CustomerSidebar />}
    </PageContainer>
  );
};

const PageContainer = styled.div``;

const Layout = styled.div`
  display: flex;
  position: relative;

  > :first-child {
    min-width: ${SIDEBAR_WIDTH}px;
  }
`;

const RightSide = styled.section<{ showingSidebar: boolean }>`
  ${(props) =>
    props.showingSidebar
      ? `
    margin-left: ${SIDEBAR_WIDTH}px;
  `
      : 'margin-left: 0px;'}
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--page-background);
  flex: 1;
  padding-top: 58px;

  ${IF_MOBILE} {
    padding-top: 0;
  }
`;

export function usePage<
  ParamsType extends Record<string, string> = Record<string, string>,
>() {
  const navigate = useNavigate();
  const navigateBack = useNavigateBack();
  const params = useParams<ParamsType>();

  return { navigate, navigateBack, ...params };
}
