import { usePage } from '@web/app/Page';
import { Pane } from '@web/components/Pane';
import { TabLink, Tabs } from '@web/components/Tabs';
import { Column } from '@web/components/layout';
import * as React from 'react';

export const FileTabs: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { organizationToken, fileToken } = usePage();

  return (
    <Column>
      <Tabs>
        <TabLink
          to={`/organizations/${organizationToken}/files/${fileToken}/details`}
        >
          Details
        </TabLink>
        <TabLink
          to={`/organizations/${organizationToken}/files/${fileToken}/embeddings`}
        >
          Embeddings
        </TabLink>
      </Tabs>
      <Pane style={{ borderTopLeftRadius: 0 }}>{children}</Pane>
    </Column>
  );
};
