import { IF_MOBILE } from '@web/app/responsive';
import { HoverAnchor, HoverLink } from '@web/components/HoverLink';
import styled, { css } from 'styled-components';

const ListItemCss = css`
  padding: 12px 18px;
  border-radius: var(--default-border-radius);
  transition: 0.3s linear background-color;

  &:hover {
    background-color: #eee;
  }

  ${IF_MOBILE} {
    margin: 0 -18px;
  }
`;

export const ListItemLink = styled(HoverLink)`
  ${ListItemCss}
`;

export const ListItemAnchor = styled(HoverAnchor)`
  ${ListItemCss}
`;
