import {
  removeAnnouncement,
  useAnnouncements,
} from '@client/AnnouncementsClient';
import { AnnouncementState, IAnnouncement } from '@shared/announcements';
import { PageContent } from '@web/app/Page';
import { useResponsive } from '@web/app/responsive';
import { useAuth } from '@web/auth/useAuth';
import { formatDate } from '@web/common/formatDate';
import { Button } from '@web/components/Button';
import { Markdown } from '@web/components/Markdown';
import { Column, Row, Spacer } from '@web/components/layout';
import {
  Header1,
  Header2,
  Small,
  Text,
  TypographyCss,
} from '@web/components/typography';
import { Skeleton, message } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const AnnouncementsPage: React.FC = () => {
  const { isMobile } = useResponsive();
  const { user } = useAuth();
  const { data: announcements, mutate: reloadAnnouncements } =
    useAnnouncements();

  if (!announcements) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const handleRemoveClicked = async (announcement: IAnnouncement) => {
    if (!confirm('Are you sure?')) {
      return;
    }

    try {
      await removeAnnouncement(announcement.token);
      void message.success('Success');
      void reloadAnnouncements();
    } catch (error) {
      void message.error('Error');
    }
  };

  const newButtonStyles: React.CSSProperties = isMobile
    ? { position: 'fixed', bottom: 24, right: 24 }
    : { position: 'absolute', top: 24, right: 24 };
  const Header = isMobile ? Header2 : Header1;

  return (
    <PageContent>
      <Column
        style={{
          maxWidth: 800,
          width: '100%',
        }}
      >
        {announcements.length > 0 ? (
          <Column gap={24}>
            {announcements.map((announcement) => (
              <Column key={announcement.token}>
                <Row gap={12}>
                  <Header>{announcement.title}</Header>
                  {announcement.state === AnnouncementState.DRAFT && (
                    <Tag>DRAFT</Tag>
                  )}
                </Row>
                <Row gap={6}>
                  <Small>
                    Posted by {announcement.author.name} on{' '}
                    {formatDate(announcement.createdDate)}
                  </Small>
                  {user.token === announcement.author.token && (
                    <>
                      <Small>•</Small>
                      <SmallLink to={announcement.token}>Edit</SmallLink>
                      <Small>•</Small>
                      <SmallLinkButton
                        onClick={() => {
                          void handleRemoveClicked(announcement);
                        }}
                      >
                        Remove
                      </SmallLinkButton>
                    </>
                  )}
                </Row>
                <Spacer size={12} />
                <Markdown value={announcement.content} />
              </Column>
            ))}
          </Column>
        ) : (
          <Text>No announcements have been created yet.</Text>
        )}
        <Row style={newButtonStyles}>
          <Link to="new">
            <Button>New</Button>
          </Link>
        </Row>
      </Column>
    </PageContent>
  );
};

const SmallLink = styled(Link)`
  ${TypographyCss.Small}
  text-decoration: underline;
`;
const SmallLinkButton = styled.span`
  ${TypographyCss.Small}
  text-decoration: underline;
  cursor: pointer;
`;

const Tag = styled(Small)`
  background: #f5f5f5;
  border: 1px solid #ccc;
  border-radius: 20px;
  padding: 1px 10px;
  font-size: 10px;
  font-weight: 500;
`;
