import { useFile } from '@client/FilesClient';
import { FileToken, IFile } from '@shared/files';
import { OrganizationToken } from '@shared/organizations';
import { PageContent, usePage } from '@web/app/Page';
import { patch } from '@web/common/api';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { FileIcon } from '@web/topics/FileIcon';
import { Breadcrumb, Button, Input, Skeleton, message } from 'antd';
import { last } from 'lodash';
import * as React from 'react';

import { EditFilePageContent } from './EditFilePageContent';

export const InternalEditFilePage: React.FC = () => {
  const { navigateBack, fileToken, organizationToken } = usePage<{
    fileToken: FileToken;
    organizationToken: OrganizationToken;
  }>();
  const { data: file } = useFile(fileToken);
  const [name, setName] = React.useState(file?.name);
  const [description, setDescription] = React.useState(file?.description);
  React.useEffect(() => {
    if (file) {
      setName(file.name);
      setDescription(file.description);
    }
  }, [file]);

  const handleOkClicked = async () => {
    if (!file || !description) {
      return;
    }

    try {
      await patch<IFile>(`/files/${file.token}`, {
        name,
        description,
      });
      navigateBack();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleCancelClicked = () => {
    navigateBack();
  };

  if (!file) {
    return (
      <PageContent>
        <Breadcrumb
          routes={[
            {
              breadcrumbName: 'All Files',
              href: `/organizations/${organizationToken}/files`,
            },
          ]}
        />
        <Spacer size={24} />
        <Skeleton />
      </PageContent>
    );
  }

  const extension = name ? last(name.split('.')) : null;
  return (
    <EditFilePageContent routes={[{ breadcrumbName: file.name }]}>
      <Column style={{ width: 400 }}>
        <Text>File Name</Text>
        <Input
          value={name}
          onChange={(e) => {
            setName(e.target.value);
          }}
          prefix={<FileIcon extension={extension} />}
        />
        <Spacer size={12} />
        <Text>Description</Text>
        <Input
          value={description}
          onChange={(e) => {
            setDescription(e.target.value);
          }}
        />
        <Spacer />
        <Row gap={6}>
          <Button
            type="primary"
            onClick={handleOkClicked}
            disabled={!file || !description}
          >
            Update
          </Button>
          <Button onClick={handleCancelClicked}>Cancel</Button>
        </Row>
      </Column>
    </EditFilePageContent>
  );
};
