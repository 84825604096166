import { DragOutlined, FolderAddOutlined } from '@ant-design/icons';
import { moveFile, moveFolder } from '@client/FilesClient';
import {
  FileToken,
  FolderToken,
  IFile,
  IFolder,
  isFolder,
  isRootFolder,
} from '@shared/files';
import { Button } from '@web/components/Button';
import { Column, Row, Spacer } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { Empty, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { DragArea } from '../../components/draggables';
import { FileList } from './FileList';
import { FolderHeader } from './FolderHeader';
import { FolderList } from './FolderList';
import { UploadNewFile } from './UploadNewFile';

export const FolderContents: React.FC<{
  folder: IFolder;
  onChange: () => void;
}> = ({ folder, onChange }) => {
  const [hidden, setHidden] = React.useState<Set<FolderToken | FileToken>>(
    new Set(),
  );
  React.useEffect(() => {
    setHidden(new Set());
  }, [folder]);

  const handleDrop = async (
    fileOrFolder: IFile | IFolder,
    destinationFolder: IFolder,
  ) => {
    const destinationToken = isRootFolder(destinationFolder)
      ? undefined
      : destinationFolder.token;
    try {
      setHidden((hidden) => {
        const newHidden = new Set(hidden);
        newHidden.add(fileOrFolder.token);
        return newHidden;
      });
      if (isFolder(fileOrFolder)) {
        await moveFolder(fileOrFolder.token, destinationToken);
      } else {
        await moveFile(fileOrFolder.token, destinationToken);
      }
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const visibleFolders = folder.folders
    ? folder.folders.filter((folder) => !hidden.has(folder.token))
    : [];
  const visibleFiles = folder.files
    ? folder.files.filter((file) => !hidden.has(file.token))
    : [];
  return (
    <DragArea
      dragOverlay={(dragItem) => (
        <DragOverlayBox>
          <DragOutlined
            style={{ fontSize: 20, color: 'var(--primary-color)' }}
          />
          <Text>{dragItem.name} </Text>
        </DragOverlayBox>
      )}
      onDrop={handleDrop}
    >
      <Column>
        <Row>
          <FolderHeader folder={folder} />
        </Row>
        <Spacer size={6} />
        {visibleFolders.length > 0 && (
          <FolderList onChange={onChange} folders={visibleFolders} />
        )}
        {visibleFiles.length > 0 && (
          <FileList onChange={onChange} files={visibleFiles} />
        )}
      </Column>
    </DragArea>
  );
};

const DragOverlayBox = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.8);
  border-radius: var(--default-border-radius);
  padding: 12px;
  width: auto;
  gap: 12px;
  align-self: center;
`;

export const EmptyFolderContents: React.FC<{
  onCreateFolderClicked: () => void;
  onChange: () => void;
}> = ({ onCreateFolderClicked, onChange }) => (
  <Column style={{ padding: 48 }}>
    <Empty description="No documents yet">
      <Column
        gap={12}
        style={{ justifyContent: 'center', alignItems: 'center' }}
      >
        <UploadNewFile text="Upload a document" onUpload={onChange} />
        <Small>OR</Small>
        <Button onClick={onCreateFolderClicked}>
          <FolderAddOutlined /> Create a folder
        </Button>
      </Column>
    </Empty>
  </Column>
);
