import { LoadingOutlined, UploadOutlined } from '@ant-design/icons';
import { FolderToken, IFile } from '@shared/files';
import { Button } from '@web/components/Button';
import { Tooltip, message } from 'antd';
import { UploadChangeParam } from 'antd/es/upload';
import { Upload, UploadFile } from 'antd/lib';
import * as React from 'react';

export const UploadNewFile: React.FC<{
  onUpload?: (newFile: IFile) => void;
  folderToken?: FolderToken;
  text?: React.ReactNode;
  style?: React.CSSProperties;
}> = ({ onUpload, folderToken, text, style }) => {
  const [uploading, setUploading] = React.useState(false);
  const handleUploadChange = (info: UploadChangeParam<UploadFile<any>>) => {
    if (info.file.status === 'uploading') {
      setUploading(true);
      return;
    } else if (info.file.status === 'done') {
      onUpload?.(info.file.response);
      void message.success(`File uploaded`);
    } else if (info.file.status === 'error') {
      void message.error(`Error`);
    }

    setUploading(false);
  };
  return (
    <Tooltip title="Upload a file">
      <Upload
        name="file"
        onChange={handleUploadChange}
        action={folderToken ? `/api/files?folder=${folderToken}` : `/api/files`}
        showUploadList={false}
        disabled={uploading}
      >
        <Button style={style}>
          {uploading ? (
            <LoadingOutlined style={{ fontSize: 14 }} />
          ) : (
            <>
              <UploadOutlined style={{ fontSize: 14, cursor: 'pointer' }} />{' '}
              {text}
            </>
          )}
        </Button>
      </Upload>
    </Tooltip>
  );
};
