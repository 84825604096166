import { PageContent } from '@web/app/Page';
import { GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Breadcrumb } from 'antd';
import { BreadcrumbProps } from 'antd/lib';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { FileTabs } from './FileTabs';

export const EditFilePageContent: React.FC<{
  children: React.ReactNode;
  routes?: BreadcrumbProps['routes'];
  primary?: React.ReactNode;
}> = ({ children, routes = [], primary }) => {
  const { organizationToken } = useParams();
  return (
    <PageContent>
      <Row style={{ height: 32 }}>
        <Breadcrumb
          routes={[
            {
              breadcrumbName: 'All Files',
              href: `/organizations/${organizationToken}/files`,
            },
            ...routes,
          ]}
        />
        <GrowingSpacer />
        {primary}
      </Row>
      <Spacer size={24} />
      <FileTabs>{children}</FileTabs>
    </PageContent>
  );
};
