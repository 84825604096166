import {
  CreateEmbeddingRequest,
  EmbeddingToken,
  EmbeddingTree,
  IEmbedding,
} from '@shared/embeddings';
import { FileToken, FolderToken, IFile, IFolder } from '@shared/files';
import { OrganizationToken } from '@shared/organizations';
import { del, get, patch, post, put } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useFilesAndFolders(organizationToken?: OrganizationToken) {
  const url = stringifyUrl({
    url: `/files`,
    query: {
      organizationToken: organizationToken,
    },
  });

  return useApi<{ files: IFile[]; folders: IFolder[] }>(url);
}

export function filesAndFolders(organizationToken?: OrganizationToken) {
  const url = stringifyUrl({
    url: `/files`,
    query: {
      organizationToken: organizationToken,
    },
  });

  return get<{ files: IFile[]; folders: IFolder[] }>(url);
}

export function searchFiles(query: string) {
  return post<{ query: string }, IFile[]>(`/files/search`, { query: query });
}

export function createFolder(
  name: string,
  description: string,
  folderToken?: FolderToken,
) {
  return post<
    {
      name: string;
      description: string;
      folder: `fo_${string}`;
    },
    IFolder
  >(`/files/folders`, {
    name: name,
    description: description,
    folder: folderToken,
  });
}

export function updateFolder(
  folderToken: FolderToken,
  name: string,
  description: string,
) {
  return patch<
    {
      name: string;
      description: string;
    },
    IFolder
  >(`/files/folders/${folderToken}`, {
    name: name,
    description: description,
  });
}

export function moveFolder(
  folderToken: FolderToken,
  destination?: FolderToken,
) {
  return put<{ destination: `fo_${string}` }>(
    `/files/folders/${folderToken}/path`,
    { destination: destination },
  );
}

export function deleteFolder(folderToken: FolderToken) {
  return del(`/files/folders/${folderToken}`);
}

export function useFile(fileToken: FileToken) {
  const url = stringifyUrl({
    url: `/files/${fileToken}`,
    query: {},
  });

  return useApi<IFile>(url);
}

export function file(fileToken: FileToken) {
  const url = stringifyUrl({
    url: `/files/${fileToken}`,
    query: {},
  });

  return get<IFile>(url);
}

export function moveFile(fileToken: FileToken, destination?: FolderToken) {
  return put<{ destination: `fo_${string}` }>(`/files/${fileToken}/path`, {
    destination: destination,
  });
}

export function deleteFile(fileToken: FileToken) {
  return del(`/files/${fileToken}`);
}

export function updateFile(fileToken: FileToken, updates: Partial<IFile>) {
  return patch<Partial<IFile>>(`/files/${fileToken}`, updates);
}

export function splitFileEmbedding(
  embeddingToken: EmbeddingToken,
  fileToken: FileToken,
) {
  return post<{}>(`/files/${fileToken}/embeddings/${embeddingToken}/split`);
}

export function updateEmbeddingTree(
  fileToken: FileToken,
  embeddingTree: EmbeddingTree,
) {
  return post<EmbeddingTree>(
    `/files/${fileToken}/embeddingTree`,
    embeddingTree,
  );
}

export function useFileEmbeddings(fileToken: FileToken) {
  const url = stringifyUrl({
    url: `/files/${fileToken}/embeddings`,
    query: {},
  });

  return useApi<{ file: IFile; embeddings: IEmbedding[] }>(url);
}

export function fileEmbeddings(fileToken: FileToken) {
  const url = stringifyUrl({
    url: `/files/${fileToken}/embeddings`,
    query: {},
  });

  return get<{ file: IFile; embeddings: IEmbedding[] }>(url);
}

export function deleteFileEmbedding(embeddingToken: EmbeddingToken) {
  return del(`/files/embeddings/${embeddingToken}`);
}

export function createFileEmbedding(
  fileToken: FileToken,
  embedding: CreateEmbeddingRequest,
) {
  return post<CreateEmbeddingRequest, IEmbedding>(
    `/files/${fileToken}/embeddings`,
    embedding,
  );
}

export function extractEstimate(fileToken: FileToken) {
  return put<{}>(`/files/${fileToken}/extract-estimate`);
}

export function uploadFile() {
  return post<{}>(`/files`);
}
