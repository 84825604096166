import {
  createAnnouncement,
  updateAnnouncement,
  useAnnouncement,
} from '@client/AnnouncementsClient';
import { AnnouncementToken } from '@shared/announcements';
import { PageContent, usePage } from '@web/app/Page';
import { PageHeader } from '@web/components/PageHeader';
import { Column, Row } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Input, Skeleton, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

export const EditAnnouncementPage: React.FC = () => {
  const navigate = useNavigate();
  const { announcementToken } = usePage<{
    announcementToken: AnnouncementToken;
  }>();
  const { data: announcement, mutate: reloadAnnouncement } = announcementToken
    ? useAnnouncement(announcementToken)
    : { data: null, mutate: () => {} };
  const isEdit = !!announcementToken;
  const [isSaving, setIsSaving] = React.useState(false);
  const [title, setTitle] = React.useState('');
  const [content, setContent] = React.useState('');
  React.useEffect(() => {
    if (announcement) {
      setTitle(announcement.title);
      setContent(announcement.content);
    }
  }, [announcement]);

  if (isEdit && !announcement) {
    return <Skeleton />;
  }

  const handleSave = async () => {
    if (!title || isSaving) {
      return;
    }

    setIsSaving(true);
    try {
      if (isEdit) {
        await updateAnnouncement(announcementToken, title, content);
        void reloadAnnouncement();
      } else {
        await createAnnouncement(title, content);
      }
      navigate(-1);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    } finally {
      setIsSaving(false);
    }
  };

  const canSave = !!title;

  return (
    <PageContent>
      <PageHeader
        title={isEdit ? 'Edit Announcement' : 'New Announcement'}
        navigateBack
      />
      <Column gap={24}>
        <FormElement>
          <Text>Title</Text>
          <Input
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
            autoFocus={!isEdit}
          />
        </FormElement>
        <FormElement>
          <Text>Content</Text>
          <TextArea
            value={content}
            onChange={(e) => {
              setContent(e.target.value);
            }}
            style={{ height: 200 }}
          />
        </FormElement>
        <Row gap={6}>
          <Button type="primary" onClick={handleSave} disabled={!canSave}>
            Save
          </Button>
          <Button
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Row>
      </Column>
    </PageContent>
  );
};

const FormElement = styled(Column)`
  gap: 12px;
  max-width: 600px;
`;
