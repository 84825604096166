import { updateEmbedding, useEmbedding } from '@client/EmbeddingsClient';
import { createFileEmbedding, useFile } from '@client/FilesClient';
import { IEmbedding } from '@shared/embeddings';
import { FileToken } from '@shared/files';
import { OrganizationToken } from '@shared/organizations';
import { useNavigateBack } from '@web/common/useNavigateBack';
import { Column, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, message } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import * as React from 'react';
import { useParams } from 'react-router-dom';

import { EditFilePageContent } from './EditFilePageContent';

export const InternalEditEmbeddingPage: React.FC = () => {
  const navigateBack = useNavigateBack();
  const { embeddingToken, fileToken, organizationToken } = useParams<{
    embeddingToken?: IEmbedding['token'];
    fileToken: FileToken;
    organizationToken: OrganizationToken;
  }>();
  const { data: file } = useFile(fileToken);
  const isEdit = !!embeddingToken;
  const { data: embedding } = isEdit
    ? useEmbedding(embeddingToken)
    : { data: undefined };
  const [text, setText] = React.useState(embedding?.text ?? '');
  const [entityToken, setEntityToken] = React.useState<FileToken>(
    isEdit ? embedding?.entityToken : fileToken,
  );
  React.useEffect(() => {
    if (embedding) {
      setText(embedding.text);
      setEntityToken(embedding.entityToken);
    }
  }, [embedding]);

  const handleOkClicked = async () => {
    if (!text || !entityToken) {
      void message.error('Missing required fields');
      return;
    }

    try {
      if (embedding) {
        void message.loading('Updating...');
        await updateEmbedding(embedding.token, {
          text,
          name: '',
          description: '',
          entityToken,
          organizationToken,
        });
      } else {
        void message.loading('Creating...');
        await createFileEmbedding(fileToken, {
          entityToken,
          text,
          name: '',
          description: '',
          organizationToken,
        });
      }
      navigateBack();
      await message.destroy();
    } catch (error) {
      await message.destroy();
      void message.error('Error');
    }
  };

  return (
    <EditFilePageContent
      routes={
        file
          ? [
              {
                breadcrumbName: file.name,
                href: `/organizations/${organizationToken}/files/${fileToken}/details`,
              },
              {
                breadcrumbName: 'Embeddings',
                href: `/organizations/${organizationToken}/files/${fileToken}/embeddings`,
              },
              embeddingToken
                ? {
                    breadcrumbName: 'Edit',
                  }
                : {
                    breadcrumbName: 'New',
                  },
            ]
          : []
      }
    >
      <Column>
        <Text>Text</Text>
        <TextArea
          value={text}
          onChange={(e) => {
            setText(e.target.value);
          }}
          autoSize
          style={{
            maxHeight: `calc(100vh - 500px)`,
            minHeight: 200,
          }}
        />
        <Spacer />
        <Row gap={6}>
          <Button type="primary" onClick={handleOkClicked}>
            Save
          </Button>
          <Button
            onClick={() => {
              navigateBack();
            }}
          >
            Cancel
          </Button>
        </Row>
      </Column>
    </EditFilePageContent>
  );
};
