import { IOrganization } from '@shared/organizations';
import { useApi } from '@web/common/useApi';
import { LinkButton } from '@web/components/LinkButton';
import { Text } from '@web/components/typography';
import { Tooltip } from 'antd';
import * as React from 'react';
import styled from 'styled-components';

import { useOrganizationFilter } from './organizationFilter';

export const OrganizationPicker: React.FC<{ onClick: () => void }> = ({
  onClick,
}) => {
  const { organizationTokenFilter } = useOrganizationFilter();
  const { data: organization } = useApi<IOrganization>(
    `/organizations/${organizationTokenFilter}`,
  );

  return (
    <Tooltip title="Manage another organization">
      <OrganizationPickerContainer>
        <Text style={{ lineHeight: '16px' }}>
          {organization?.name ?? 'None'}
        </Text>
        <LinkButton onClick={onClick}>Switch Organizations</LinkButton>
      </OrganizationPickerContainer>
    </Tooltip>
  );
};
const OrganizationPickerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  align-items: flex-start;
  cursor: pointer;
  margin: 0;
  max-width: ${240 - 48}px;

  .ant-typography {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .ant-btn svg {
    font-size: 14px;
  }

  > * {
    outline: 0;
  }
`;
