import { ClockCircleOutlined } from '@ant-design/icons';
import { Row } from '@web/components/layout';
import { DatePicker, Select } from 'antd';
import { addDays, format } from 'date-fns';
import dayjs from 'dayjs';
import * as React from 'react';

function parseDayjs(dateString: string) {
  return dayjs(new Date(`${dateString}T23:59:59.999Z`));
}

const hourOptions = [
  {
    value: 0,
    label: '00:00am',
  },
  {
    value: 1,
    label: '01:00am',
  },
  {
    value: 2,
    label: '02:00am',
  },
  {
    value: 3,
    label: '03:00am',
  },
  {
    value: 4,
    label: '04:00am',
  },
  {
    value: 5,
    label: '05:00am',
  },
  {
    value: 6,
    label: '06:00am',
  },
  {
    value: 7,
    label: '07:00am',
  },
  {
    value: 8,
    label: '08:00am',
  },
  {
    value: 9,
    label: '09:00am',
  },
  {
    value: 10,
    label: '10:00am',
  },
  {
    value: 11,
    label: '11:00am',
  },
  {
    value: 12,
    label: '12:00pm',
  },
  {
    value: 13,
    label: '01:00pm',
  },
  {
    value: 14,
    label: '02:00pm',
  },
  {
    value: 15,
    label: '03:00pm',
  },
  {
    value: 16,
    label: '04:00pm',
  },
  {
    value: 17,
    label: '05:00pm',
  },
  {
    value: 18,
    label: '06:00pm',
  },
  {
    value: 19,
    label: '07:00pm',
  },
  {
    value: 20,
    label: '08:00pm',
  },
  {
    value: 21,
    label: '09:00pm',
  },
  {
    value: 22,
    label: '10:00pm',
  },
  {
    value: 23,
    label: '11:00pm',
  },
];

export const SelectDateTime: React.FC<{
  date: string;
  hour: number; // 0-23
  disabled?: boolean;
  onChange: (date: string, hour: number) => void;
}> = ({
  date = format(addDays(new Date(), 7), 'yyyy-MM-dd'),
  hour = 9,
  onChange,
  disabled,
}) => {
  const dayjsDate = parseDayjs(date);

  const handleDatePickerChanged = (newDate: dayjs.Dayjs) => {
    if (!newDate) {
      return;
    }

    const strDate = newDate.format('YYYY-MM-DD');

    onChange(strDate, hour);
  };

  const handleHourChanged = (newHour: number) => {
    onChange(date, newHour);
  };

  return (
    <Row gap={6}>
      <DatePicker
        value={dayjsDate}
        disabled={disabled}
        onChange={handleDatePickerChanged}
      />
      <Select
        value={hour}
        style={{ width: 150 }}
        onChange={(newHour) => {
          handleHourChanged(newHour);
        }}
        suffixIcon={<ClockCircleOutlined />}
      >
        {hourOptions.map((hourOption) => (
          <Select.Option key={hourOption.value} value={hourOption.value}>
            {hourOption.label}
          </Select.Option>
        ))}
      </Select>
    </Row>
  );
};
