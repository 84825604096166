import { IFile } from '@shared/files';
import { patch } from '@web/common/api';
import { Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { FileIcon } from '@web/topics/FileIcon';
import { Input, Modal, message } from 'antd';
import { last } from 'lodash';
import * as React from 'react';

export const EditFileModal: React.FC<{
  file: IFile;
  onSave: (fileSaved: IFile) => void;
  onClose: () => void;
}> = ({ file, onClose, onSave }) => {
  const [name, setName] = React.useState(file.name);
  const [description, setDescription] = React.useState(file.description);

  const handleOkClicked = async () => {
    if (!file || !name) {
      return;
    }

    try {
      const fileSaved = await patch<IFile>(`/files/${file.token}`, {
        name,
        description,
      });
      onSave(fileSaved);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const extension = name ? last(name.split('.')) : null;
  return (
    <Modal
      title="Edit Document"
      open={true}
      onOk={handleOkClicked}
      onCancel={onClose}
      okButtonProps={{ disabled: !file }}
      width={500}
      okText="Save"
    >
      <Spacer size={12} />
      <Text>Name</Text>
      <Input
        value={name}
        onChange={(e) => {
          setName(e.target.value);
        }}
        prefix={<FileIcon extension={extension} />}
      />
      <Spacer size={12} />
      <Text>Description</Text>
      <Input
        value={description}
        onChange={(e) => {
          setDescription(e.target.value);
        }}
      />
      <Spacer />
    </Modal>
  );
};
