import {
  CloseCircleOutlined,
  DragOutlined,
  EditOutlined,
  FileTextOutlined,
  SplitCellsOutlined,
} from '@ant-design/icons';
import {
  deleteFileEmbedding,
  splitFileEmbedding,
  updateEmbeddingTree,
} from '@client/FilesClient';
import {
  EmbeddingToken,
  EmbeddingTree,
  IEmbedding,
  IEmbeddingNode,
  ROOT_EMBEDDING_TOKEN,
} from '@shared/embeddings';
import { usePage } from '@web/app/Page';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { StopPropagation } from '@web/components/StopPropagation';
import { DragArea, Draggable, Droppable } from '@web/components/draggables';
import { Column, GrowingSpacer, Row, Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Button, Empty, Skeleton, Tooltip, message } from 'antd';
import * as React from 'react';
import styled from 'styled-components';
import { useMap } from 'usehooks-ts';

type EmbeddingNodeMap = Omit<
  Map<EmbeddingToken, IEmbeddingNode>,
  'set' | 'clear' | 'delete'
>;

export const EmbeddingsList: React.FC<{
  entityToken: IEmbedding['entityToken'];
  embeddingTree?: EmbeddingTree;
  embeddingMap: Map<EmbeddingToken, IEmbedding>;
  onChange: () => void;
}> = ({ embeddingTree, entityToken, embeddingMap, onChange }) => {
  const [nodeMap, actions] = useMap<EmbeddingToken, IEmbeddingNode>();
  React.useEffect(() => {
    if (embeddingMap) {
      const embeddingNodes = createEmbeddingNodes(embeddingMap, embeddingTree);
      for (const node of embeddingNodes) {
        actions.set(node.token, node);
      }
    }
  }, [embeddingMap]);

  interface IIndexDroppable {
    token: string;
    parent: EmbeddingToken;
    index: number;
  }

  const handleDrop = async (
    dragging: IEmbeddingNode,
    droppedOver: IIndexDroppable,
  ) => {
    // ignore things dragged onto themselves
    if (droppedOver.parent === dragging.token) {
      return;
    }

    // update new parent node
    const parentNode = nodeMap.get(droppedOver.parent);
    const before = parentNode.children
      .slice(0, droppedOver.index)
      .filter((child) => child !== dragging.token);
    const after = parentNode.children
      .slice(droppedOver.index)
      .filter((child) => child !== dragging.token);
    const newParentNode = {
      ...parentNode,
      children: [...before, dragging.token, ...after],
    };
    actions.set(newParentNode.token, newParentNode);

    // if item was dragged to a new parent
    if (newParentNode.token !== dragging.parent) {
      const existingParentNode = nodeMap.get(dragging.parent);
      const updatedParentNode: IEmbeddingNode = {
        ...existingParentNode,
        children: existingParentNode.children.filter(
          (embeddingToken) => embeddingToken !== dragging.token,
        ),
      };
      const newChildNode = {
        ...dragging,
        parent: newParentNode.token,
      };
      actions.set(updatedParentNode.token, updatedParentNode);
      actions.set(newChildNode.token, newChildNode);
    }
  };

  const handleSaveEmbeddings = async () => {
    const rootNode = nodeMap.get(ROOT_EMBEDDING_TOKEN);
    const embeddingTree = populateEmbeddingTree(rootNode, nodeMap);
    try {
      await updateEmbeddingTree(entityToken, embeddingTree);
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  const rootNode = nodeMap.get(ROOT_EMBEDDING_TOKEN);
  return (
    <Column>
      <Row>
        <GrowingSpacer />
        <Button
          style={{ position: 'sticky', top: 24 }}
          onClick={handleSaveEmbeddings}
        >
          Save
        </Button>
      </Row>
      <DragArea
        activationDelay={0}
        dragOverlay={(dragItem) => (
          <DragOverlayBox>
            <DragOutlined
              style={{ fontSize: 20, color: 'var(--primary-color)' }}
            />
            <Text>{dragItem.name} </Text>
          </DragOverlayBox>
        )}
        onDrop={handleDrop}
      >
        <Column>
          {rootNode ? (
            rootNode.children.length === 0 ? (
              <Empty />
            ) : (
              <EmbeddingItemList
                node={rootNode}
                nodeMap={nodeMap}
                onChange={onChange}
              />
            )
          ) : (
            <Skeleton />
          )}
        </Column>
      </DragArea>
    </Column>
  );
};

const createEmbeddingNodes = (
  embeddingMap: Map<EmbeddingToken, IEmbedding>,
  embeddingTree?: EmbeddingTree,
): IEmbeddingNode[] => {
  let embeddingNodes: IEmbeddingNode[] = [];
  const parentMap = new Map<EmbeddingToken, EmbeddingToken>();
  if (embeddingTree) {
    for (const embeddingToken of Object.keys(embeddingTree) as any) {
      const node: IEmbeddingNode = {
        value: embeddingMap.get(embeddingToken),
        token: embeddingToken,
        children: embeddingTree[embeddingToken] ?? [],
      };
      for (const childToken of node.children) {
        parentMap.set(childToken, node.token);
      }
      embeddingNodes.push(node);
    }

    for (const node of embeddingNodes) {
      node.parent = parentMap.get(node.token);
    }
  } else {
    const embeddings = Array.from(embeddingMap.values());
    embeddingNodes = embeddings.map((embedding) => {
      return {
        value: embedding,
        token: embedding.token,
        children: [],
        parent: ROOT_EMBEDDING_TOKEN,
      };
    });
    embeddingNodes.push({
      value: { token: ROOT_EMBEDDING_TOKEN },
      token: ROOT_EMBEDDING_TOKEN,
      children: Array.from(embeddingMap.keys()),
    });
  }

  return embeddingNodes;
};

const EmbeddingItemList: React.FC<{
  node: IEmbeddingNode;
  nodeMap: EmbeddingNodeMap;
  onChange: () => void;
}> = ({ node, nodeMap, onChange }) => {
  if (node.children.length === 0) {
    return null;
  }

  return (
    <Column>
      {node.children.map((embeddingToken, index) => (
        <React.Fragment key={`embedding-${embeddingToken}`}>
          <EmbeddingDroppableSpace index={index} parent={node.token} />
          <EmbeddingItem
            embeddingToken={embeddingToken}
            nodeMap={nodeMap}
            onChange={onChange}
          />
        </React.Fragment>
      ))}
      <EmbeddingDroppableSpace
        index={node.children.length}
        parent={node.token}
      />
    </Column>
  );
};

const EmbeddingItem: React.FC<{
  embeddingToken: EmbeddingToken;
  nodeMap: EmbeddingNodeMap;
  onChange: () => void;
}> = ({ embeddingToken, nodeMap, onChange }) => {
  const { navigate } = usePage();
  const { confirm, contextHolder } = useModalConfirm();
  const node = nodeMap.get(embeddingToken);
  if (!node) {
    return null;
  }

  const embedding = node.value;

  const handleRemoveClicked = async () => {
    const confirmed = await confirm(
      'Do you wish to permanently remove this embedding?',
      { title: 'Delete Embedding' },
    );
    if (!confirmed) {
      return;
    }

    try {
      await deleteFileEmbedding(embeddingToken);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  const handleSplitClicked = async () => {
    const confirmed = await confirm('Do you wish to split this embedding?', {
      title: 'Split Embedding',
    });
    if (!confirmed) {
      return;
    }

    try {
      await splitFileEmbedding(embeddingToken, embedding.entityToken);
      void message.success('Success');
      onChange();
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column>
      <Draggable data={node}>
        <Droppable
          id={`droppable_${node.token}_${node.children.length}_root`}
          data={{ index: node.children.length, parent: node.token }}
        >
          <EmbeddingItemRow>
            <FileTextOutlined
              style={{
                alignSelf: 'flex-start',
                marginTop: 2,
                fontSize: 18,
                color: '#666',
              }}
            />
            <Spacer size={12} />
            <Text
              style={{
                lineHeight: '18px',
                whiteSpace: 'pre-wrap',
              }}
            >
              {embedding.text}
            </Text>
            <GrowingSpacer />
            <StopPropagation>
              <Row>
                <Tooltip title="Split embedding">
                  <Button type="text" size="small" onClick={handleSplitClicked}>
                    <SplitCellsOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Edit embedding">
                  <Button
                    type="text"
                    size="small"
                    onClick={() => {
                      navigate(node.value.token);
                    }}
                  >
                    <EditOutlined />
                  </Button>
                </Tooltip>
                <Tooltip title="Remove embedding">
                  <Button
                    type="text"
                    size="small"
                    onClick={handleRemoveClicked}
                  >
                    <CloseCircleOutlined />
                  </Button>
                </Tooltip>
              </Row>
            </StopPropagation>
          </EmbeddingItemRow>
        </Droppable>
      </Draggable>
      <Column style={{ paddingLeft: 24 }}>
        <EmbeddingItemList node={node} nodeMap={nodeMap} onChange={onChange} />
      </Column>
      {contextHolder}
    </Column>
  );
};

const EmbeddingDroppableSpace: React.FC<{
  parent: EmbeddingToken;
  index: number;
}> = ({ index, parent }) => {
  return (
    <Droppable
      id={`droppable_${parent}_${index}`}
      data={{ token: `index_${index}`, index, parent }}
    >
      <Spacer />
    </Droppable>
  );
};

const EmbeddingItemRow = styled(Row)`
  padding: 12px;
  border-radius: var(--default-border-radius);
  cursor: grab;
  outline: 0;

  &:hover {
    background-color: #f5f5f5;
  }
`;

const DragOverlayBox = styled.div`
  display: flex;
  background: rgba(255, 255, 255, 0.8);
  border-radius: var(--default-border-radius);
  padding: 12px;
  width: auto;
  gap: 12px;
  align-self: center;
`;

const populateEmbeddingTree = (
  node: IEmbeddingNode,
  nodeMap: EmbeddingNodeMap,
  embeddingTree: EmbeddingTree = {},
) => {
  embeddingTree[node.token] = node.children;
  for (const childToken of node.children) {
    populateEmbeddingTree(nodeMap.get(childToken), nodeMap, embeddingTree);
  }

  return embeddingTree;
};
