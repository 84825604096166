import { Button } from '@web/components/Button';
import { Pane } from '@web/components/Pane';
import { Result } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { PageContent } from './Page';

export const NotFoundPage: React.FC = () => {
  return (
    <PageContent>
      <Pane>
        <Result
          status={404}
          title="This page does not exist"
          extra={
            <Link to="/documents">
              <Button>Return to Documents</Button>
            </Link>
          }
        />
      </Pane>
    </PageContent>
  );
};
