import styled from 'styled-components';

import { TypographyCss } from './typography';

export const Button = styled.button`
  ${TypographyCss.Text}
  appearance: none;
  border: 0;
  background: #fff;
  height: 32px;
  padding: 0 18px;
  border-radius: 4px;
  cursor: pointer;
  border: 1px solid #ddd;

  &:hover {
    border: 1px solid #aaa;
  }
`;

export const DarkButton = styled(Button)`
  background: #333;
  box-shadow: var(--default-drop-shadow);
  color: white;
  border: 0;

  &:hover {
    border: 0;
    box-shadow: var(--hover-drop-shadow);
    background: #000;
  }
`;

export const PrimaryButton = styled(DarkButton)`
  background: var(--primary-color);

  &:hover {
    background: var(--ant-primary-7);
  }
`;
