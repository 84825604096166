import { DeleteOutlined, EditOutlined, FolderFilled } from '@ant-design/icons';
import { deleteFolder } from '@client/FilesClient';
import { IFolder } from '@shared/files';
import { useResponsive } from '@web/app/responsive';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Button } from '@web/components/Button';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, Row, Spacer } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { message } from 'antd';
import * as React from 'react';

import { Draggable, Droppable } from '../../components/draggables';
import { EditFolderModal } from './EditFolderModal';
import { ListItemLink } from './listItems';

export const FolderList: React.FC<{
  folders: IFolder[];
  onChange?: () => void;
}> = ({ folders, onChange }) => {
  return (
    <Column>
      {folders.map((folder) => {
        return (
          <FolderItem
            key={`folder-item-${folder.token}`}
            folder={folder}
            onChange={onChange}
          />
        );
      })}
    </Column>
  );
};

const FolderItem: React.FC<{
  folder: IFolder;
  onChange?: () => void;
}> = ({ folder, onChange }) => {
  const { isMobile } = useResponsive();
  const { confirm, contextHolder } = useModalConfirm();
  const [editingFolder, setEditingFolder] = React.useState<IFolder>();

  const handleDeleteClicked = async (folder: IFolder) => {
    const confirmed = await confirm(`Do you want to remove ${folder.name}?`);
    if (!confirmed) {
      return;
    }

    try {
      await deleteFolder(folder.token);
      onChange?.();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Draggable data={folder}>
      <Droppable data={folder} id={`folder_${folder.token}`}>
        <ListItemLink to={`/explore/${folder.token}`}>
          <Row>
            <FolderFilled style={{ fontSize: 26, color: '#eed358' }} />
            <Spacer size={12} />
            <Column style={{ flex: 1 }}>
              <Text style={{ lineHeight: '18px', fontWeight: 500 }}>
                {folder.name}
              </Text>
              <Small
                style={{
                  fontStyle: folder.description ? undefined : 'italic',
                }}
              >
                {folder.description}
              </Small>
            </Column>
            {!isMobile && (
              <Row gap={6}>
                <StopPropagation>
                  <Button
                    className="show-on-hover"
                    onClick={() => {
                      void handleDeleteClicked(folder);
                    }}
                  >
                    <DeleteOutlined style={{ color: '#333' }} />
                  </Button>
                </StopPropagation>
                <StopPropagation>
                  <Button
                    className="show-on-hover"
                    onClick={() => setEditingFolder(folder)}
                  >
                    <EditOutlined style={{ color: '#333' }} />
                  </Button>
                </StopPropagation>
              </Row>
            )}
          </Row>
          {contextHolder}
          {editingFolder && (
            <EditFolderModal
              folder={editingFolder}
              onSave={() => {
                onChange?.();
                setEditingFolder(null);
              }}
              onClose={() => {
                setEditingFolder(null);
              }}
            />
          )}
        </ListItemLink>
      </Droppable>
    </Draggable>
  );
};
