import { ITopic, TopicState } from '@shared/topics';
import { UserRole } from '@shared/types';
import { PageContent } from '@web/app/Page';
import { useAuth } from '@web/auth/useAuth';
import { CommentsList } from '@web/comments/CommentsList';
import { useApi } from '@web/common/useApi';
import { Button } from '@web/components/Button';
import { Markdown } from '@web/components/Markdown';
import { PageHeader } from '@web/components/PageHeader';
import { Spacer } from '@web/components/layout';
import { Text } from '@web/components/typography';
import { Divider, Skeleton } from 'antd';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { CloseTopicModal } from './CloseTopicModal';
import { TopicAttachments } from './TopicAttachments';
import { TopicInfo } from './TopicInfo';

export const TopicPage: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { topicToken } = useParams();
  const { data, mutate: reloadTopic } = useApi(`/topics/${topicToken}`);
  const [showCloseModal, setShowCloseModal] = React.useState(false);
  if (!data) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const topic: ITopic = data.topic;
  const isOwner =
    topic.ownerToken === user.token || user.role === UserRole.PRESIDENT;
  const hasContent = topic.content?.length > 0;
  const handleEditTopic = () => {
    navigate(`/topics/${topicToken}/edit`);
  };
  const handleTopicClosed = () => {
    setShowCloseModal(false);
    void reloadTopic();
  };

  return (
    <PageContent>
      <PageHeader
        title={topic.title}
        navigateBack
        extras={
          topic.state === TopicState.OPEN && isOwner ? (
            <Button
              onClick={() => {
                setShowCloseModal(true);
              }}
            >
              Close topic
            </Button>
          ) : null
        }
        description={<TopicInfo topic={topic} />}
        onEdit={
          isOwner && topic.state === TopicState.OPEN
            ? handleEditTopic
            : undefined
        }
      />
      <Divider style={{ marginTop: 0 }} />
      {hasContent ? (
        <Markdown value={topic.content} />
      ) : (
        <Text>No description provided</Text>
      )}
      <Spacer />
      <TopicAttachments
        topic={topic}
        onChange={reloadTopic}
        readonly={topic.state === TopicState.CLOSED}
      />
      <Spacer />
      <CommentsList
        entityToken={topic.token}
        readonly={topic.state === TopicState.CLOSED}
        showReply
        placeholder="Leave a comment"
      />
      {showCloseModal && (
        <CloseTopicModal
          topic={topic}
          onClose={() => {
            setShowCloseModal(false);
          }}
          onTopicClosed={handleTopicClosed}
        />
      )}
    </PageContent>
  );
};
