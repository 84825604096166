import { Column } from '@web/components/layout';
import * as React from 'react';
import { FileIcon as ReactFileIcon, defaultStyles } from 'react-file-icon';
import styled from 'styled-components';

export const FileIcon: React.FC<{
  extension: string;
  size?: number;
  style?: React.CSSProperties;
}> = ({ extension, size = 14, style }) => {
  return (
    <Container size={size} style={style}>
      <ReactFileIcon extension={extension} {...defaultStyles[extension]} />
    </Container>
  );
};
const Container = styled(Column)<{ size: number }>`
  svg {
    min-width: ${(props) => props.size}px;
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
`;
