import {
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  useDraggable,
  useDroppable,
} from '@dnd-kit/core';
import { Column } from '@web/components/layout';
import * as React from 'react';
import styled, { css } from 'styled-components';

interface IHaveToken {
  token: string;
}

export const DragArea: React.FC<{
  children: React.ReactNode;
  dragOverlay: (item: any) => React.ReactNode;
  onDrop: (dragItem: any, dropItem: any) => void;
  activationDelay?: number;
}> = ({ children, dragOverlay, onDrop, activationDelay = 100 }) => {
  const [draggingItem, setDraggingItem] = React.useState(null);

  const handleDragStart = (dragStartEvent: DragStartEvent) => {
    setDraggingItem(dragStartEvent.active.data.current);
  };

  const handleDragEnd = (dragEndEvent: DragEndEvent) => {
    const dragItem = dragEndEvent.active?.data?.current;
    const dropItem = dragEndEvent.over?.data?.current;
    if (!dragItem || !dropItem || dragItem === dropItem) {
      return;
    }

    onDrop(dragItem, dropItem);
    setDraggingItem(null);
  };

  return (
    <DndContext
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      sensors={[
        {
          sensor: MouseSensor,
          options: {
            activationConstraint: { delay: activationDelay, tolerance: 5 },
          },
        },
      ]}
    >
      {children}
      <DragOverlay>
        {draggingItem ? dragOverlay(draggingItem) : null}
      </DragOverlay>
    </DndContext>
  );
};

export const Draggable: React.FC<{
  children: React.ReactNode;
  data: IHaveToken;
}> = ({ children, data }) => {
  const { attributes, listeners, isDragging, setNodeRef } = useDraggable({
    id: `draggable_${data.token}`,
    data,
  });

  return (
    <DragDropContainer
      isDragging={isDragging}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {children}
    </DragDropContainer>
  );
};

interface IDroppableType {
  children: React.ReactNode;
  data: any;
  id: string;
}
export const Droppable: React.FC<IDroppableType> = ({ children, data, id }) => {
  const { isOver, setNodeRef } = useDroppable({
    id,
    data,
  });

  return (
    <DragDropContainer isOver={isOver} ref={setNodeRef}>
      {children}
    </DragDropContainer>
  );
};

const DragDropContainer = styled(Column)<{
  isDragging?: boolean;
  isOver?: boolean;
}>`
  outline: 0;

  ${(props) =>
    props.isOver
      ? css`
          background-color: var(--ant-primary-1);
        `
      : ''}

  ${(props) =>
    props.isDragging
      ? css`
          background-color: #eee;
          border: 0;
        `
      : ''}
`;
