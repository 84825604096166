import { IFeatureOverride } from './features';
import { IOrganization, OrganizationToken } from './organizations';
import { EntityReactionSummaryMap } from './reactions';

export enum UserRole {
  SUPER = 'SUPER',
  RESIDENT = 'RESIDENT',
  DIRECTOR = 'DIRECTOR',
  PRESIDENT = 'PRESIDENT',
  PROPERTY_MANAGER = 'PROPERTY_MANAGER',
}

export const UserRoleLabels: Record<UserRole, string> = {
  [UserRole.SUPER]: 'Super',
  [UserRole.DIRECTOR]: 'Director',
  [UserRole.RESIDENT]: 'Resident',
  [UserRole.PRESIDENT]: 'President',
  [UserRole.PROPERTY_MANAGER]: 'Property Manager',
};

export const CUSTOMER_ROLES = [
  UserRole.DIRECTOR,
  UserRole.PRESIDENT,
  UserRole.RESIDENT,
  UserRole.PROPERTY_MANAGER,
];

export const CUSTOMER_DIRECTOR_ROLES = [
  UserRole.DIRECTOR,
  UserRole.PRESIDENT,
  UserRole.PROPERTY_MANAGER,
];

export const CUSTOMER_ADMIN_ROLES = [
  UserRole.PRESIDENT,
  UserRole.PROPERTY_MANAGER,
];

export type UserToken = `u_${string}`;

export function isToken<TokenType extends string>(
  token: string | undefined,
  tokenPrefix: string,
): token is TokenType {
  return !!token?.startsWith(tokenPrefix);
}

export interface IEntity {
  createdDate: Date;
  updatedDate: Date;
  deletedDate?: Date;
}

export interface IUser extends IEntity {
  token: UserToken;
  name: string;
  email: string;
  organizationToken?: OrganizationToken;
  password: string;
  role: UserRole;
  active: boolean;
  invitedDate?: Date;
  firstLoginDate?: Date;
  avatarImageUrl?: string;
}

export interface ISession {
  user?: IUser;
  organization?: IOrganization;
  impersonator?: IUser;
  features?: IFeatureOverride[];
}

export interface ISearchQuery {
  skip: number;
  limit: number;
}

export interface ISearchResults<T> {
  results: T[];
  total: number;
  userTokenNameMap?: UserTokenNameMap;
  userMap?: UserMap;
  reactionSummaryMap?: EntityReactionSummaryMap;
}

export type UserMapItem = Pick<
  IUser,
  | 'name'
  | 'email'
  | 'token'
  | 'avatarImageUrl'
  | 'organizationToken'
  | 'deletedDate'
  | 'role'
>;
export type UserMap = Record<UserToken, UserMapItem>;
export type UserTokenNameMap = Record<UserToken, string>;
