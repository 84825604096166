import React, { createContext } from 'react';

export interface IPageContextContextValue {
  pageDetails?: IPageDetails;
  setPageDetails: (details: IPageDetails) => void;
}

export interface IPageAction {
  icon?: React.ReactNode;
  onClick?: () => void;
  linkTo?: string;
  label?: string;
  disabled?: boolean;
}

export interface IConfirmDetails {
  title?: string;
  description?: string;
  okText?: string;
  cancelText?: string;
}

export interface IPageDetails {
  title?: string;
  navigateBack?: boolean;
  defaultNavigateBackTo?: string;
  primaryAction?: IPageAction;
  secondaryActions?: IPageAction[];
}

export const PageContext = createContext<IPageContextContextValue>({
  setPageDetails: () => {},
  pageDetails: {},
});
