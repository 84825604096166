import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { deleteFile } from '@client/FilesClient';
import { IFile } from '@shared/files';
import { useModalConfirm } from '@web/common/useModalConfirm';
import { Button } from '@web/components/Button';
import { StopPropagation } from '@web/components/StopPropagation';
import { Column, Row } from '@web/components/layout';
import { Small, Text } from '@web/components/typography';
import { EditFileModal } from '@web/meetings/agenda_items/EditFileModal';
import { FileIcon } from '@web/topics/FileIcon';
import { message } from 'antd';
import { last } from 'lodash';
import * as React from 'react';

import { Draggable } from '../../components/draggables';
import { ListItemAnchor } from './listItems';

export const FileList: React.FC<{
  files: IFile[];
  readonly?: boolean;
  onChange?: () => void;
}> = ({ files, readonly, onChange }) => {
  const { confirm, contextHolder } = useModalConfirm();
  const [editingFile, setEditingFile] = React.useState<IFile>();

  const handleDeleteClicked = async (file: IFile) => {
    const confirmed = await confirm(`Do you want to remove ${file.name}?`);
    if (!confirmed) {
      return;
    }

    try {
      await deleteFile(file.token);
      onChange?.();
      void message.success('Success');
    } catch (error) {
      void message.error('Error');
    }
  };

  return (
    <Column>
      {files.map((file) => {
        const extension = last(file.name.split('.'));
        return (
          <Draggable data={file} key={`file-item-${file.token}`}>
            <ListItemAnchor
              href={`/api/files/${file.token}/view`}
              target="_blank"
              rel="noreferrer"
            >
              <Row gap={12} style={{ alignItems: 'flex-start' }}>
                <FileIcon
                  extension={extension}
                  size={28}
                  style={{ margin: 0 }}
                />
                <Column style={{ flex: 1 }}>
                  <Text style={{ lineHeight: '18px', fontWeight: 500 }}>
                    {file.name}
                  </Text>
                  <Small
                    style={{
                      fontStyle: file.description ? undefined : 'italic',
                    }}
                  >
                    {file.description ?? 'No description'}
                  </Small>
                </Column>
                {!readonly && (
                  <Row gap={6}>
                    <StopPropagation>
                      <Button
                        className="show-on-hover"
                        onClick={() => {
                          void handleDeleteClicked(file);
                        }}
                      >
                        <DeleteOutlined style={{ color: '#333' }} />
                      </Button>
                    </StopPropagation>
                    <StopPropagation>
                      <Button
                        className="show-on-hover"
                        onClick={() => setEditingFile(file)}
                      >
                        <EditOutlined style={{ color: '#333' }} />
                      </Button>
                    </StopPropagation>
                  </Row>
                )}
              </Row>
              {contextHolder}
              {editingFile && (
                <EditFileModal
                  file={editingFile}
                  onSave={() => {
                    onChange?.();
                    setEditingFile(null);
                  }}
                  onClose={() => {
                    setEditingFile(null);
                  }}
                />
              )}
            </ListItemAnchor>
          </Draggable>
        );
      })}
      {contextHolder}
    </Column>
  );
};
