// import { generateToken } from '@backend/common/generateToken';
import { useFilesAndFolders } from '@client/FilesClient';
import { IFile, IFolder } from '@shared/files';
import { mapByToken } from '@shared/mapByToken';
import { cloneDeep, last } from 'lodash';
import { useEffect, useState } from 'react';

const ROOT_FOLDER: IFolder = {
  token: 'fo_root',
  name: 'Documents',
  path: [],
  pathFolders: [],
  folders: [],
};

export const useFolder = (token?: IFolder['token']) => {
  const { data, mutate } = useFilesAndFolders();
  const [allFiles, setAllFiles] = useState<IFile[]>([]);
  const [allFolders, setAllFolders] = useState<IFolder[]>([]);
  useEffect(() => {
    if (data) {
      setAllFiles(data.files);
      setAllFolders(data.folders);
    }
  }, [data]);

  if (!data) {
    return {};
  }

  const { folderTokenMap, rootFolder } = processFolders(allFolders, allFiles);
  return { data: token ? folderTokenMap.get(token) : rootFolder, mutate };
};

const processFolders = (allFolders: IFolder[], allFiles: IFile[]) => {
  const clonedFolders = cloneDeep(allFolders);
  const folderTokenMap = mapByToken(clonedFolders);
  const rootFolder: IFolder = {
    ...ROOT_FOLDER,
    name: 'Documents',
    folders: clonedFolders.filter((folder) => folder.path.length === 0),
    files: [],
  };

  for (const folder of clonedFolders) {
    folder.pathFolders = folder.path.map((pathToken) =>
      folderTokenMap.get(pathToken),
    );
    folder.parentFolder =
      folder.path.length > 0
        ? folderTokenMap.get(last(folder.path))
        : rootFolder;
    folder.files = [];
  }

  for (const file of allFiles) {
    if (file.path.length > 0) {
      const parentFolderToken = last(file.path);
      const folder = folderTokenMap.get(parentFolderToken);
      folder.files.push(file);
    } else {
      rootFolder.files.push(file);
    }
  }

  folderTokenMap.set(rootFolder.token, rootFolder as any);
  for (const folder of clonedFolders) {
    folder.folders = clonedFolders.filter(
      (f) => f.parentFolder.token === folder.token,
    );
  }

  return { folderTokenMap, rootFolder };
};
