import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const HoverLink = styled(Link)`
  .show-on-hover {
    opacity: 0;
  }

  &:hover .show-on-hover {
    opacity: 1;
  }
`;

export const HoverAnchor = styled.a`
  .show-on-hover {
    opacity: 0;
  }

  &:hover .show-on-hover {
    opacity: 1;
  }
`;
