import { useFileEmbeddings } from '@client/FilesClient';
import { EmbeddingToken, IEmbedding } from '@shared/embeddings';
import { FileToken } from '@shared/files';
import { mapByToken } from '@shared/mapByToken';
import { usePage } from '@web/app/Page';
import { Button, Skeleton } from 'antd';
import * as React from 'react';
import { Link } from 'react-router-dom';

import { EditFilePageContent } from './EditFilePageContent';
import { EmbeddingsList } from './EmbeddingsList';

export const InternalFileEmbeddingsPage: React.FC = () => {
  const { fileToken, organizationToken } = usePage<{
    fileToken: FileToken;
    organizationToken: FileToken;
  }>();
  const { data, mutate: reloadEmbeddings } = useFileEmbeddings(fileToken);

  if (!data) {
    return (
      <EditFilePageContent>
        <Skeleton />
      </EditFilePageContent>
    );
  }

  const { file, embeddings } = data;
  const embeddingMap = mapByToken<IEmbedding, EmbeddingToken>(embeddings);
  return (
    <EditFilePageContent
      routes={[
        {
          breadcrumbName: file.name,
          href: `/organizations/${organizationToken}/files/${fileToken}/details`,
        },
        { breadcrumbName: 'Embeddings' },
      ]}
      primary={
        <Link to="new">
          <Button type="primary">Add new</Button>
        </Link>
      }
    >
      <EmbeddingsList
        entityToken={fileToken}
        embeddingTree={file.embeddingTree}
        embeddingMap={embeddingMap}
        onChange={reloadEmbeddings}
      />
    </EditFilePageContent>
  );
};
