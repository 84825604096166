import styled from 'styled-components';

export const LinkButton = styled.button`
  appearance: none;
  border: 0;
  background: none;
  padding: 0;
  margin: 0;
  color: var(--primary-color);
  display: inline;
  text-align: left;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;
