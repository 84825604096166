import { ISession, IUser, UserRole, UserToken } from '@shared/types';
import { useApi } from '@web/common/useApi';
import { message } from 'antd';

import { del, post, put } from '../common/api';

export const useAuth = () => {
  const {
    data: session,
    error,
    mutate: reloadSession,
  } = useApi<ISession>(`/auth/session`);
  const user: IUser = session?.user;
  const features = session?.features;
  const organization = session?.organization;
  const impersonator = session?.impersonator;
  const isLoading = error === undefined && session === undefined;
  const isLoggedIn = user !== undefined;
  const validateRole = (requiredRole: UserRole): boolean => {
    return user?.role === requiredRole;
  };
  const isResident = user?.role === UserRole.RESIDENT;
  const isDirector = user?.role === UserRole.DIRECTOR;
  const isPropertyManager = user?.role === UserRole.PROPERTY_MANAGER;
  const isSuper = user?.role === UserRole.SUPER;
  const isImpersonating = !!impersonator;

  return {
    isResident,
    isDirector,
    isPropertyManager,
    isSuper,
    isLoggedIn,
    isLoading,
    isImpersonating,
    user,
    impersonator,
    organization,
    error,
    login,
    loginWithCode,
    logout,
    impersonate,
    unimpersonate,
    validateRole,
    reloadSession,
    features,
  };
};

async function logout(): Promise<void> {
  try {
    await del('/auth/session');
    window.location.replace('/auth/login');
  } catch (error) {
    void message.error('Error');
  }
}

async function impersonate(
  userToken: UserToken,
  navigateTo?: string,
): Promise<void> {
  localStorage.impersonateReturnPath = window.location.pathname;
  try {
    await put(`/auth/impersonate/${userToken}`);
    window.location.href = navigateTo ?? '/redirect';
  } catch (error) {
    void message.error('Failed');
  }
}

async function unimpersonate(): Promise<void> {
  await del(`/auth/impersonate`);
  window.location.href = localStorage.impersonateReturnPath
    ? localStorage.impersonateReturnPath
    : '/users';
}

async function login(email: string, password: string): Promise<IUser> {
  return await post<IUser>(`/auth/login`, { email, password });
}

async function loginWithCode(email: string, code: string): Promise<IUser> {
  return await post(`/auth/loginWithCode`, { email, code });
}
