import {
  CreateEmbeddingRequest,
  EmbeddingToken,
  IEmbedding,
  UpdateEmbeddingRequest,
} from '@shared/embeddings';
import { del, get, patch, post } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { stringifyUrl } from 'query-string/base';

export function useEmbeddings(entityToken?: IEmbedding['entityToken']) {
  const url = stringifyUrl({
    url: `/embeddings/list`,
    query: {
      entityToken: entityToken,
    },
  });

  return useApi<IEmbedding[]>(url);
}

export function embeddings(entityToken?: IEmbedding['entityToken']) {
  const url = stringifyUrl({
    url: `/embeddings/list`,
    query: {
      entityToken: entityToken,
    },
  });

  return get<IEmbedding[]>(url);
}

export function useEmbedding(embeddingToken: IEmbedding['token']) {
  const url = stringifyUrl({
    url: `/embeddings/${embeddingToken}`,
    query: {},
  });

  return useApi<IEmbedding>(url);
}

export function embedding(embeddingToken: IEmbedding['token']) {
  const url = stringifyUrl({
    url: `/embeddings/${embeddingToken}`,
    query: {},
  });

  return get<IEmbedding>(url);
}

export function deleteEmbedding(embeddingToken: EmbeddingToken) {
  return del(`/embeddings/${embeddingToken}`);
}

export function createEmbedding(embedding: CreateEmbeddingRequest) {
  return post<CreateEmbeddingRequest, IEmbedding>(`/embeddings`, embedding);
}

export function updateEmbedding(
  embeddingToken: EmbeddingToken,
  embedding: UpdateEmbeddingRequest,
) {
  return patch<UpdateEmbeddingRequest, IEmbedding>(
    `/embeddings/${embeddingToken}`,
    embedding,
  );
}
