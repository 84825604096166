import { mapByToken } from '@shared/mapByToken';
import { ActionItemToken, IActionItem } from '@shared/meetings';
import { PageContent } from '@web/app/Page';
import { patch } from '@web/common/api';
import { useApi } from '@web/common/useApi';
import { DateDivider } from '@web/components/DateDivider';
import { Column, Row } from '@web/components/layout';
import { Header1, Text } from '@web/components/typography';
import { Skeleton, message } from 'antd';
import { reverse, sortBy } from 'lodash';
import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ActionItemDrawer } from './agenda_items/action_items/ActionItemDrawer';
import { ActionItemRow } from './agenda_items/action_items/ActionItemRow';

export const ActionItemsPage: React.FC = () => {
  const navigate = useNavigate();
  const { actionItemToken } = useParams<{ actionItemToken: ActionItemToken }>();
  const { data: actionItems, mutate: reloadActionItems } =
    useApi<IActionItem[]>(`/action_items`);
  if (!actionItems) {
    return (
      <PageContent>
        <Skeleton />
      </PageContent>
    );
  }

  const closedActionItems = reverse(
    sortBy(
      actionItems.filter((actionItem) => !!actionItem.resolvedDate),
      'date.date',
    ),
  );

  const openActionItems = sortBy(
    actionItems.filter((actionItem) => !actionItem.resolvedDate),
    'date.date',
  );

  const handleResolved = async (
    updatedItem: IActionItem,
    resolved: boolean,
  ) => {
    try {
      await patch<IActionItem>(`/action_items/${updatedItem.token}`, {
        title: updatedItem.title,
        resolvedDate: resolved ? new Date() : null,
      });
      void reloadActionItems();
    } catch (error) {
      void message.error('Error');
    }
  };

  const actionItemMap = mapByToken(actionItems);
  return (
    <PageContent>
      <Column>
        <Row gap={12}>
          <Header1 style={{ whiteSpace: 'nowrap' }}>Action Items</Header1>
        </Row>
        <DateDivider>Open</DateDivider>
        <Column>
          {openActionItems.length > 0 ? (
            openActionItems.map((actionItem) => (
              <ActionItemRow
                key={actionItem.token}
                actionItem={actionItem}
                onResolve={handleResolved}
                sortable={false}
                showAgendaItem
                linkTo={`/action-items/${actionItem.token}`}
              />
            ))
          ) : (
            <Text>No open actions found</Text>
          )}
        </Column>
        {closedActionItems.length > 0 && (
          <>
            <DateDivider>Resolved</DateDivider>
            <Column>
              {closedActionItems.map((actionItem) => (
                <ActionItemRow
                  key={actionItem.token}
                  actionItem={actionItem}
                  onResolve={handleResolved}
                  sortable={false}
                  showAgendaItem
                  linkTo={`/action-items/${actionItem.token}`}
                />
              ))}
            </Column>
          </>
        )}
      </Column>
      {actionItemToken && actionItemMap.has(actionItemToken) && (
        <ActionItemDrawer
          actionItem={actionItemMap.get(actionItemToken)}
          onClose={() => {
            navigate(`/action-items`);
          }}
          onChange={reloadActionItems}
        />
      )}
    </PageContent>
  );
};
