import { FileToken } from './files';
import { OrganizationToken } from './organizations';

export type EmbeddingToken = `e_${string}`;
export interface IEmbedding {
  token: EmbeddingToken;
  entityToken: FileToken;
  organizationToken: OrganizationToken;
  /**
   * A name used to list this embedding for administrators
   */
  name: string;
  /**
   * A description to be provide to a LLM giving context as to where the text originated
   */
  description: string;
  /**
   * The text to be injected into an LLM prompt given a specific user question
   */
  text: string;
  embedding: number[];
  /**
   * The number of tokens that the text string will be parsed into
   */
  tokenCount: number;
  createdDate: Date;
}
export interface IEmbeddingDistance extends IEmbedding {
  /**
   * Cosine distance between this embedding and the reference text
   */
  distance: number;
}
export type CreateEmbeddingRequest = Pick<
  IEmbedding,
  'name' | 'entityToken' | 'description' | 'text' | 'organizationToken'
>;
export type UpdateEmbeddingRequest = Pick<
  IEmbedding,
  'name' | 'entityToken' | 'description' | 'text' | 'organizationToken'
>;

export interface IEmbeddingNode {
  token: EmbeddingToken;
  value: Partial<IEmbedding>;
  parent?: EmbeddingToken;
  children?: EmbeddingToken[];
}

export type EmbeddingTree = Record<EmbeddingToken, EmbeddingToken[]>;
export const ROOT_EMBEDDING_TOKEN: EmbeddingToken = 'e_root';
